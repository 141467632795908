.common-button {
  border: 1px solid #5e5e5e !important;
  border-radius: 3px !important;
  padding: 5px 10px !important;
  background-color: #fff;
  cursor: pointer;
}

.common-input {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px 10px;
  background-color: #fff;
}
