@import url("https://fonts.googleapis.com/css?family=Teko|Noto+Sans|Source+Sans+Pro:300,400,600");

* {
  font-family: "Nunito Sans", sans-serif;
}

.mx-3 {
  margin-left: 3px;
  margin-right: 3px;
}

.my-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

@media screen and (max-width: 600px) {
  .flex-vertical {
    flex-direction: column;
  }

  button,
  input {
    padding: 5px 10px;
    text-align: center;
  }

  .mx-3 {
    margin-left: 0px;
    margin-right: 0px;
  }
}

input {
  border-style: solid !important;
}
