@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  font-size: xx-large;
  font-weight: 600;
  margin: 1rem auto;
}

body {
  font-size: medium;
}
